import { useJSONFetcher, JSONFetcherOptions } from "@outplayed/json-fetcher";
import { getApiRoot } from "@ugg/shared/api/api-helpers";
import { useUGGPatches } from "../patches";
import { useUGGApiVersions } from "@ugg/shared/api/requests/ugg-api-versions";
import { PrimaryRoles } from "@ugg/shared/interfaces/champions/primary-roles.interface";

function useChampionRolesURL({ fallback }: { fallback?: boolean } = {}) {
  const patches = useUGGPatches();
  const patch = fallback ? patches?.data?.[1] : patches?.data?.[0];
  const { data: version } = useUGGApiVersions({
    apiKey: "primary_roles",
    patch: patch,
    skip: !patch,
  });

  if (patch && version) {
    return `${getApiRoot()}/primary_roles/${patch}/${version}.json`;
  } else {
    return null;
  }
}

export function usePrimaryRoles(options?: JSONFetcherOptions & { fallback?: boolean }) {
  const { fallback, ssr = false, skip = false } = options || {};
  const primaryRolesURL = useChampionRolesURL({ fallback });
  return useJSONFetcher<PrimaryRoles>(primaryRolesURL || "", {
    skip: skip || !primaryRolesURL,
    ssr,
  });
}
