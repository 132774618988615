import { getOverview, OverviewInfo, ExtendedOverviewInfo } from "@ugg/shared/api/data-parser/champions/overview";
import { Multibuilds, KaynFormIds } from "@ugg/shared/interfaces/champions/overview.interface";

export const multibuildTransformMap: { [key in Multibuilds]?: number } = {
  [Multibuilds.REDKAYN]: KaynFormIds.RED,
  [Multibuilds.BLUEKAYN]: KaynFormIds.BLUE,
};

export const champion_transform_map: { [key: number]: number[] } = {
  141: [KaynFormIds.RED, KaynFormIds.BLUE],
};

export function getDefaultChampionTransformId(championId: number) {
  return champion_transform_map[championId] ? champion_transform_map[championId][0] : championId;
}

export function isChampionTransform(championId: number) {
  return championId > 10000;
}

export function getChampionOverview(
  championId: number,
  championOverviews: { [key: number]: { [key: string]: OverviewInfo } },
  selector: string,
) {
  return championOverviews?.[championId]?.[selector];
}

export function convertObjectToArray(obj: TabData) {
  const arr = Object.entries(obj);
  arr.sort((a, b) => {
    if (a[0] === Multibuilds.RECOMMENDED) return -1;
    return b[1].matches - a[1].matches;
  });
  return arr;
}

export function capitalizeMulti(multi: string, fallback = "Recommended") {
  switch (multi) {
    case Multibuilds.AP:
    case Multibuilds.AD:
      return multi.toUpperCase();
    case Multibuilds.TANK:
    case Multibuilds.CRIT:
    case Multibuilds.LETHALITY:
      return multi.substring(0, 1).toUpperCase() + multi.substring(1);
    case Multibuilds.ONHIT:
      return "On Hit";
    case Multibuilds.REDKAYN:
      return "Red";
    case Multibuilds.BLUEKAYN:
      return "Blue";
    default:
      return fallback;
  }
}

export function shouldEnableBuild(filteredOverview: OverviewInfo, filteredTargetOverview: OverviewInfo, bootItems: number[]) {
  const defaultKeyItem = filteredOverview?.rec_core_items?.ids
    ? getClassifyItem(filteredOverview?.rec_core_items?.ids, bootItems)
    : 0;
  const targetKeyItem = filteredTargetOverview?.rec_core_items?.ids
    ? getClassifyItem(filteredTargetOverview?.rec_core_items?.ids, bootItems)
    : 0;
  const shouldEnableType =
    filteredTargetOverview &&
    defaultKeyItem &&
    targetKeyItem &&
    !(targetKeyItem === defaultKeyItem) &&
    filteredTargetOverview?.item_options_1 &&
    filteredTargetOverview.item_options_1.length > 1 &&
    filteredTargetOverview?.item_options_2 &&
    filteredTargetOverview.item_options_2.length > 1 &&
    filteredTargetOverview?.item_options_3 &&
    filteredTargetOverview.item_options_3.length > 1 &&
    (filteredTargetOverview?.rec_starting_items?.matches ?? 0) > 9 &&
    (filteredTargetOverview?.rec_core_items?.matches ?? 0) > 9;
  return shouldEnableType;
}

export function getClassifyItem(items: number[], bootItems: number[] | undefined) {
  if (!items.length || !bootItems || !bootItems.length) {
    return 0;
  }
  let normal = 0;
  let others = 0;
  for (let i = 0; i < items.length; i++) {
    if (!bootItems.includes(items[i]) && normal === 0) {
      normal = items[i];
    } else {
      others = items[i];
    }
  }
  return normal !== 0 ? normal : others;
}

export interface MultipleChampionOverviewData {
  forceDefaultData: boolean;
  championOverview: Record<string, OverviewInfo>;
  apChampionOverview: Record<string, OverviewInfo>;
  tankChampionOverview: Record<string, OverviewInfo>;
  adChampionOverview: Record<string, OverviewInfo>;
  critChampionOverview: Record<string, OverviewInfo>;
  lethalityChampionOverview: Record<string, OverviewInfo>;
  onHitChampionOverview: Record<string, OverviewInfo>;
  fetchingChampionOverview: boolean;
  errorChampionOverview: Error | null;
}

export function cleanMultipleOverviewData(generatedOverviews: ExtendedOverviewInfo[] | null, bootItems: number[]) {
  if (!generatedOverviews || !generatedOverviews[0]) {
    return null;
  }

  const defaultOverview = generatedOverviews[0];
  const defaultType = generatedOverviews[0].type;
  const defaultKeyItem = defaultOverview?.rec_core_items?.ids
    ? getClassifyItem(defaultOverview?.rec_core_items?.ids, bootItems)
    : 0;

  return generatedOverviews
    .filter((val) => {
      const enoughData =
        (val?.item_options_1?.length ?? 0) > 1 &&
        (val?.item_options_2?.length ?? 0) > 1 &&
        (val?.item_options_3?.length ?? 0) > 1 &&
        (val?.rec_starting_items?.matches ?? 0) > 9 &&
        (val?.rec_core_items?.matches ?? 0) > 9;
      const isMatchingDefault =
        val.type !== defaultType ? getClassifyItem(val?.rec_core_items?.ids ?? [], bootItems) !== defaultKeyItem : true;

      return isChampionTransform(Number(val.type)) || val.type === defaultType ? true : enoughData && isMatchingDefault;
    })
    .sort((a, b) => {
      if (isChampionTransform(Number(a.type))) {
        return Number(a.type) - Number(b.type); // Sort by champion id
      }
      if (a.type === Multibuilds.RECOMMENDED) return -1; // Recommended should always be first

      return b.matches - a.matches;
    })
    .slice(0, 4);
}

export function parseCleanMultipleOverviewData(
  data: ExtendedOverviewInfo[],
  transformChampionId: number,
  multi: string | undefined,
) {
  return data.find(({ type }) => {
    return Number(type) === transformChampionId || type === multi;
  });
}

export function generateMultipleOverviews(
  data: Record<number, MultipleChampionOverviewData>,
  championId: number,
  fullParams: {
    queueType: string;
    region: string;
    rank: string;
    role: string;
  },
) {
  const transformChampionId = getDefaultChampionTransformId(championId);
  const isTransformedChamp = isChampionTransform(transformChampionId);
  const isLoading = isTransformedChamp
    ? champion_transform_map[championId]
        .map((val: number) => {
          return data[val]["fetchingChampionOverview"];
        })
        .some((val) => val)
    : data[championId]["fetchingChampionOverview"];

  if (isLoading) {
    return null;
  }

  let overviewObjs: Array<[OverviewInfo | null, string]> = isTransformedChamp
    ? champion_transform_map[championId].map((val: number) => {
        return [getOverview(data[val]["championOverview"], fullParams), val + ""];
      })
    : [
        [getOverview(data[championId]["championOverview"], fullParams), Multibuilds.RECOMMENDED],
        [getOverview(data[championId]["apChampionOverview"], fullParams), Multibuilds.AP],
        [getOverview(data[championId]["tankChampionOverview"], fullParams), Multibuilds.TANK],
        [getOverview(data[championId]["adChampionOverview"], fullParams), Multibuilds.AD],
        [getOverview(data[championId]["critChampionOverview"], fullParams), Multibuilds.CRIT],
        [getOverview(data[championId]["lethalityChampionOverview"], fullParams), Multibuilds.LETHALITY],
        [getOverview(data[championId]["onHitChampionOverview"], fullParams), Multibuilds.ONHIT],
      ];

  let cleanedOverviewObjs = overviewObjs.filter((val) => {
    return val[0] ? true : false;
  }) as Array<[OverviewInfo, string]>;

  return cleanedOverviewObjs.map((val) => {
    return { ...val[0], type: val[1] };
  });
}

export type TabData = Record<string, { keystone: number | undefined; item: number; matches: number }>;
export function generateTabs(cleanedMultipleOverviews: ExtendedOverviewInfo[] | null, bootItems: number[]) {
  const tabData: TabData = {};
  if (cleanedMultipleOverviews) {
    cleanedMultipleOverviews.forEach((val) => {
      if (val) {
        tabData[val.type] = {
          keystone: val?.rec_runes?.active_perks[0],
          item: val?.rec_core_items?.ids ? getClassifyItem(val?.rec_core_items?.ids, bootItems) : 0,
          matches: val.matches ? val.matches : 0,
        };
      }
    });
  }

  return tabData;
}

export function parseChampionOverview(
  championId: number,
  championOverviews: Record<number, MultipleChampionOverviewData>,
  multi: string,
) {
  if (Object.keys(championOverviews).length === 0) {
    return null;
  } else if (isChampionTransform(championId) || !multi || multi === Multibuilds.RECOMMENDED) {
    return championOverviews[championId]["championOverview"];
  }
  const overviewKey = overviewKeyObjMap[multi];
  return overviewKey ? championOverviews[championId][overviewKey] : null;
}

const overviewKeyObjMap: {
  [key: string]: keyof Omit<
    MultipleChampionOverviewData,
    "forceDefaultData" | "fetchingChampionOverview" | "errorChampionOverview"
  >;
} = {
  [Multibuilds.RECOMMENDED]: "championOverview",
  [Multibuilds.AD]: "adChampionOverview",
  [Multibuilds.AP]: "apChampionOverview",
  [Multibuilds.TANK]: "tankChampionOverview",
  [Multibuilds.CRIT]: "critChampionOverview",
  [Multibuilds.LETHALITY]: "lethalityChampionOverview",
  [Multibuilds.ONHIT]: "onHitChampionOverview",
};
