import { useJSONFetcher } from "@outplayed/json-fetcher";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useUGGApiVersions } from "../ugg-api-versions";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { validParams } from "@ugg/shared/query-params/valid-params";
import { getApiRoot } from "@ugg/shared/api/api-helpers";
import { cleanOverviewData } from "@ugg/shared/api/data-parser/champions/overview";
import { champion_transform_map, MultipleChampionOverviewData } from "@ugg/shared/utils/multi-build-helpers";
import { CHAMPION_PAGES } from "@ugg/shared/pages/champion-pages";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";

import { Overview, Multibuilds } from "@ugg/shared/interfaces/champions/overview.interface";
import { OverviewInfo } from "@ugg/shared/api/data-parser/champions/overview";
import { getKey } from "@ugg/shared/api/data-parser/champions/common";
import {
  OVERVIEW_EMERALD_PLUS_WORLD,
  OVERVIEW_EMERALD_PLUS_WORLD_AP,
  OVERVIEW_EMERALD_PLUS_WORLD_CT,
} from "@ugg/shared/constants/constants";
import { RankS } from "@ugg/shared/utils/rank-helpers";
import { RegionS } from "@ugg/shared/utils/region-helpers";

interface ChampionOverviewOption {
  queueType?: string;
  patch?: string;
  opp?: number;
}

export function skipChampionQuery(championId: number | undefined | null) {
  return !championId || championId < 0;
}

function useChampionOverviewURL(championId: number, options: ChampionOverviewOption) {
  let { queueType, patch, opp } = options;

  const { data: version } = useUGGApiVersions({ apiKey: "overview", patch });

  const hasOpp = opp && (queueType === QueueTypeS.RANKED_SOLO || queueType === QueueTypeS.RANKED_FLEX);

  // backend saves to /urf/ url instead of /arurf/
  if (queueType === QueueTypeS.ARURF) {
    queueType = QueueTypeS.URF;
  }

  const championIdParam = !hasOpp ? championId : `matchups/${championId}_${opp}`;
  const root = getApiRoot(patch);

  return [`${root}/overview`, patch, queueType, championIdParam, `${version}.json`].join("/");
}

function useCTChampionOverviewURL(championId: number, options: ChampionOverviewOption) {
  const { data: version } = useUGGApiVersions({ apiKey: "overview", patch: options.patch });

  if (options.queueType !== (QueueTypeS.RANKED_SOLO || QueueTypeS.RANKED_FLEX)) {
    delete options.opp;
  }

  // backend saves to /urf/ url instead of /arurf/
  if (options.queueType === QueueTypeS.ARURF) {
    options.queueType = QueueTypeS.PICK_URF;
  }

  const championIdParam = !options.opp ? championId : `matchups/${championId}_${options.opp}`;
  const root = getApiRoot(options.patch);

  const path = [`${root}/ct-overview`, options.patch, options.queueType, championIdParam, `${version}.json`].join("/");
  return path;
}

// Champion Multi-Build Overview
export function useSelectedChampionOverviewURL(championId: number, options: ChampionOverviewOption, selector: Multibuilds) {
  const { data: version } = useUGGApiVersions({ apiKey: "overview", patch: options.patch });

  if (options.queueType !== QueueTypeS.RANKED_SOLO && options.queueType !== QueueTypeS.RANKED_FLEX) {
    delete options.opp;
  }

  // backend saves to /urf/ url instead of /arurf/
  if (options.queueType === QueueTypeS.ARURF) {
    options.queueType = QueueTypeS.URF;
  }

  const championIdParam = !options.opp ? championId : `matchups/${championId}_${options.opp}`;
  const root = getApiRoot(options.patch);

  const path = [
    `${root}/${selector !== Multibuilds.RECOMMENDED ? [selector, "overview"].join("-") : "overview"}`,
    options.patch,
    options.queueType,
    championIdParam,
    `${version}.json`,
  ].join("/");
  //console.log(path)
  return path;
}

export function useSelectedChampionOverview(
  championId: number,
  selector: Multibuilds,
  options?: { params?: Record<string, string>; ssr?: boolean; skip?: boolean },
) {
  const { params = {}, ssr = false, skip = false } = options || {};
  const { getChampionIdByName } = getRiotAssetsContext();
  const validateQueryParams = useValidateQueryParams(validParams);
  const validatedQueryParams = validateQueryParams(CHAMPION_PAGES.OVERVIEW, params, true);

  const championOverviewURL = useSelectedChampionOverviewURL(
    championId,
    {
      ...validatedQueryParams,
      opp: getChampionIdByName(validatedQueryParams.opp),
    },
    selector,
  );

  const customCacheKey = `${OVERVIEW_EMERALD_PLUS_WORLD}_${selector}::${championOverviewURL}`;
  return useJSONFetcher(championOverviewURL, {
    ssr,
    skip: skip,
    customCacheKey:
      validatedQueryParams.region === RegionS.WORLD &&
      validatedQueryParams.rank === RankS.EMERALD_PLUS &&
      validatedQueryParams.queueType === QueueTypeS.RANKED_SOLO
        ? customCacheKey
        : "",
    onCompleted: (url, json, cacheKey) => {
      const cleanData = cleanOverviewData(json);

      const dataKey = getKey(validatedQueryParams.region, validatedQueryParams.rank, "");

      if (cacheKey.match(`${OVERVIEW_EMERALD_PLUS_WORLD}_${selector}::${championOverviewURL}`)) {
        return Object.fromEntries(Object.entries(cleanData || {}).filter(([key, value]) => key.match(dataKey)));
      }

      return cleanData;
    },
  });
}

export function useChampionOverview(
  championId: number,
  options?: { params?: Record<string, string>; ssr?: boolean; skip?: boolean },
) {
  const { params = {}, ssr = false, skip = false } = options || {};

  const { getChampionIdByName } = getRiotAssetsContext();
  const validateQueryParams = useValidateQueryParams(validParams);
  const validatedQueryParams = validateQueryParams(CHAMPION_PAGES.OVERVIEW, params, true);

  const championOverviewURL = useChampionOverviewURL(championId, {
    ...validatedQueryParams,
    opp: getChampionIdByName(validatedQueryParams.opp),
  });

  const recCacheKey = `${OVERVIEW_EMERALD_PLUS_WORLD}_${Multibuilds.RECOMMENDED}::${championOverviewURL}`;

  const fetchState = useJSONFetcher<Overview, ReturnType<typeof cleanOverviewData>>(championOverviewURL, {
    ssr,
    skip,
    onCompleted: (url, json, cacheKey) => {
      const cleanData = cleanOverviewData(json);

      const dataKey = getKey(validatedQueryParams.region, validatedQueryParams.rank, "");

      if (cacheKey.match(recCacheKey)) {
        return Object.fromEntries(Object.entries(cleanData || {}).filter(([key, value]) => key.match(dataKey)));
      }

      return cleanData;
    },
    customCacheKey:
      validatedQueryParams.region === RegionS.WORLD &&
      validatedQueryParams.rank === RankS.EMERALD_PLUS &&
      validatedQueryParams.queueType === QueueTypeS.RANKED_SOLO
        ? recCacheKey
        : "",
  });

  return { ...fetchState, params: validatedQueryParams };
}

export function useMultipleChampionOverview(
  championId: number,
  options?: { params?: Record<string, string>; ssr?: boolean; skip?: boolean },
) {
  const { params = {}, ssr = false, skip = false } = options || {};

  const res: Record<number, MultipleChampionOverviewData> = {};
  const maxBuilds = Object.values(champion_transform_map).reduce((acc, curr) => {
    return Math.max(curr.length, acc);
  }, 1);
  const baseArray = Array(maxBuilds).fill(0);
  const champions = champion_transform_map[championId]
    ? champion_transform_map[championId].concat(baseArray.slice(champion_transform_map[championId].length))
    : [championId].concat(baseArray.slice(1));
  let otherChampOverviews: {
    [key: number]: {
      [key: string]: {
        targetChampionOverview: Record<string, OverviewInfo>;
        fetchingTargetChampionOverview: boolean;
        errorTargetChampionOverview: Error | null;
      };
    };
  } = {};

  champions.forEach((championId) => {
    //Get champion transform from new location
    let {
      data: championOverview,
      loading: fetchingChampionOverview,
      error: errorChampionOverview,
    } = championId > 10000
      ? useCTChampionOverview(championId, { ssr, params, skip: skip || skipChampionQuery(championId) })
      : useChampionOverview(championId, { ssr, params, skip: skip || skipChampionQuery(championId) });

    [Multibuilds.AP, Multibuilds.TANK, Multibuilds.AD, Multibuilds.CRIT, Multibuilds.LETHALITY, Multibuilds.ONHIT].forEach(
      (val) => {
        let {
          data: targetChampionOverview,
          loading: fetchingTargetChampionOverview,
          error: errorTargetChampionOverview,
        } = useSelectedChampionOverview(championId % 10000, val, {
          params,
          ssr,
          skip: skip || skipChampionQuery(championId),
        });
        if (!otherChampOverviews[championId]) {
          otherChampOverviews[championId] = {
            [val]: {
              targetChampionOverview: targetChampionOverview,
              fetchingTargetChampionOverview: fetchingTargetChampionOverview,
              errorTargetChampionOverview: errorTargetChampionOverview,
            },
          };
        } else {
          otherChampOverviews[championId][val] = {
            targetChampionOverview: targetChampionOverview,
            fetchingTargetChampionOverview: fetchingTargetChampionOverview,
            errorTargetChampionOverview: errorTargetChampionOverview,
          };
        }
      },
    );

    const forcedDefaultParams = { ...params, opp: "allChampions" };
    const forceDefaultData = !(params.opp && championOverview && !fetchingChampionOverview);
    const {
      data: defaultChampionOverview,
      loading: fetchingDefaultChampionOverview,
      error: errorDefaultChampionOverview,
    } = championId > 10000
      ? useCTChampionOverview(championId, { ssr, params: forcedDefaultParams, skip: !forceDefaultData })
      : useChampionOverview(championId, {
          ssr,
          params: forcedDefaultParams,
          skip: !forceDefaultData || skipChampionQuery(championId),
        });

    if (forceDefaultData) {
      championOverview = defaultChampionOverview;
      fetchingChampionOverview = fetchingDefaultChampionOverview;
      errorChampionOverview = errorDefaultChampionOverview;
    }

    const errorChampOverview = errorChampionOverview
      ? Error("Champion Overview fetch Failed.")
      : false ||
        Object.keys(otherChampOverviews[championId])
          .map((val: string) => {
            return otherChampOverviews[championId][val]["errorTargetChampionOverview"];
          })
          .filter((val) => val)?.[0];

    const fetchingChampOverview =
      fetchingChampionOverview ||
      Object.keys(otherChampOverviews[championId])
        .map((val: string) => {
          return otherChampOverviews[championId][val]["fetchingTargetChampionOverview"];
        })
        .some((val) => val);

    res[championId] = {
      forceDefaultData: forceDefaultData,
      championOverview: championOverview,
      apChampionOverview: otherChampOverviews[championId]["ap"]["targetChampionOverview"],
      tankChampionOverview: otherChampOverviews[championId]["tank"]["targetChampionOverview"],
      adChampionOverview: otherChampOverviews[championId]["ad"]["targetChampionOverview"],
      critChampionOverview: otherChampOverviews[championId]["crit"]["targetChampionOverview"],
      lethalityChampionOverview: otherChampOverviews[championId]["lethality"]["targetChampionOverview"],
      onHitChampionOverview: otherChampOverviews[championId]["onhit"]["targetChampionOverview"],
      fetchingChampionOverview: fetchingChampOverview,
      errorChampionOverview: errorChampOverview,
    };
  });

  return res;
}

export function useCTChampionOverview(
  championId: number,
  { params = {}, ssr, skip }: { params?: Record<string, string>; ssr?: boolean; skip?: boolean } = {},
) {
  const { getChampionIdByName } = getRiotAssetsContext();
  const validateQueryParams = useValidateQueryParams(validParams);
  const validatedQueryParams = validateQueryParams(CHAMPION_PAGES.OVERVIEW, params, true);

  const championOverviewURL = useCTChampionOverviewURL(championId, {
    ...validatedQueryParams,
    opp: getChampionIdByName(validatedQueryParams.opp),
  });

  return useJSONFetcher(championOverviewURL, {
    ssr,
    skip,
    customCacheKey: getCustomCacheKey(validatedQueryParams, `${OVERVIEW_EMERALD_PLUS_WORLD_CT}::${championOverviewURL}`),
    onCompleted: (_url, json, cacheKey) => {
      return processOnCompleted(json, cacheKey, OVERVIEW_EMERALD_PLUS_WORLD_CT, validatedQueryParams);
    },
  });
}

function getCustomCacheKey(params: { [key: string]: string }, customCacheKey: string) {
  return params.region === RegionS.WORLD && params.rank === RankS.EMERALD_PLUS && params.queueType === QueueTypeS.RANKED_SOLO
    ? customCacheKey
    : "";
}

function processOnCompleted(json: Overview, cacheKey: string, cacheKeyMatch: string, params: { [key: string]: string }) {
  const cleanData = cleanOverviewData(json);

  const dataKey = getKey(params.region, params.rank, "");

  if (cacheKey.match(cacheKeyMatch)) {
    return Object.fromEntries(Object.entries(cleanData || {}).filter(([key, _value]) => key.match(dataKey)));
  }
  return cleanData;
}
