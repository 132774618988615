import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { usePrimaryRoles } from "./primary-roles";
import { RoleC } from "@ugg/shared/utils/role-helpers";

/* 
  - Get primary role of champion
  - Uses primary role data from last two patches + backup champions from blasted.gg
  - URL based roles are NOT considered (search params, pathname params)
*/
export function useChampionPrimaryRole(
  championId: number,
  options?: { ssr?: boolean; skip?: boolean },
): {
  data: string | undefined;
  loading: boolean;
  error: Error | null;
} {
  const { ssr = false, skip = false } = options || {};
  const { useBackupChampions } = getRiotAssetsContext();
  const backupChampions = useBackupChampions();
  const { data: primaryRoles, loading: fetchingPrimaryRoles, error: primaryRolesError } = usePrimaryRoles({ ssr, skip });
  const {
    data: fallbackPrimaryRoles,
    loading: fetchingFallbackPrimaryRoles,
    error: fallbackPrimaryRolesError,
  } = usePrimaryRoles({
    ssr,
    skip: skip || !!primaryRoles || fetchingPrimaryRoles,
    fallback: true,
  });

  const roles = primaryRoles || fallbackPrimaryRoles || {};
  let role = undefined;

  if (championId in roles) {
    role = RoleC.convertToString(roles[championId][0]);
  } else if (backupChampions?.[championId]?.primaryRole) {
    role = backupChampions[championId].primaryRole;
  }

  return {
    data: role,
    loading: fetchingPrimaryRoles || fetchingFallbackPrimaryRoles,
    error: primaryRolesError || fallbackPrimaryRolesError,
  };
}
